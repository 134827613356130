<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import traningDetail from "./traning-detail";
import traningSurvey from "./traning-survey";
/**
 * Form Repeater component
 */
export default {
  page: {
    title: "培训课程",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout,traningDetail,traningSurvey},
  data() {
    return {
      action:"new",
      traningCode:null,
      title: "培训课程",
    };
  },
  created(){
    if(this.$route.query.traningCode!=undefined){
      this.traningCode = this.$route.query.traningCode;
    }
    if(this.$route.query.action!=undefined){
      this.action = this.$route.query.action;
    }
  },
  mounted() {
  },
  computed:{
  },
  watch:{
  },
  methods: {
    handleBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<template>
    <Layout>
    <div class="row mt-4">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
              </div>
              <div class="col-sm-6">
                <div class="float-end d-none d-md-block">
                  <a href="javascript:" @click="handleBack" class="btn btn-success">返回</a>
                </div>
              </div>
            </div>
            <b-tabs content-class="p-3">
              <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-calendar-check"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">课程信息</span>
                </template>
                <traning-detail :traningCode="traningCode" :action="action"></traning-detail>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-edit"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">问卷调查</span>
                </template>
                <traning-survey :traningCode="traningCode"></traning-survey>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-edit"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">培训报名</span>
                </template>
                <div>待开发</div>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-edit"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">培训签到</span>
                </template>
                <div>待开发</div>
              </b-tab>
            </b-tabs>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
<style lang="scss">
.is-invalid .mx-input-wrapper .mx-input{
    border: 1px solid #ec4561 !important;
  }
  .is-invalid .mx-input-wrapper .mx-icon-calendar{
    color: #ec4561 !important;
  }
</style>
