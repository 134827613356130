<script>
import Swal from "sweetalert2";
import {saveTraning,traningDetail} from '@/apis/traning'
import {
  required
} from "vuelidate/lib/validators";
/**
 * Form Repeater component
 */
export default {
  validations: {
    form: {
      traningName: { 
            required,
      },
      traningAddress: { 
            required,
      },
      traningDate: { 
            required,
      },
    },
  },
  data() {
    return {
      title: "培训课程",
      action:"new",
      traningCode:null,
      form:{
        id:null,
        traningCode:"",
        traningName:"",
        traningAddress:"",
        traningDate:"",
        traningDetail:"",
        status:'N',
      },
      submitted:false,
    };
  },
  created(){
    if(this.$route.query.traningCode!=undefined){
      this.traningCode = this.$route.query.traningCode;
    }
    if(this.$route.query.action!=undefined){
      this.action = this.$route.query.action;
    }
    this.init();
  },
  mounted() {
  },
  computed:{
  },
  watch:{
  },
  methods: {
    async init(){
      // 订单数据载入
      if(this.traningCode!=null){
        traningDetail(this.traningCode).then((res)=>{
          this.form = res.data
        }).catch((res)=>{
          if(res.message=='ERR_NO_EXIST'){
            Swal.fire("System Error","培训课程不存在", "error").then(()=>{
              this.$router.go(-1)
            });
          }else{
            Swal.fire("System Error", "请求异常，请联系管理员", "error").then(()=>{
              this.$router.go(-1)
            });
          }
        })
      }
    },
    handleSaveTraning() {
      this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        } else {
          let params = {...this.form}
          saveTraning(params).then(()=>{
            this.handleBack();
          })
        }
    },
  },
};
</script>

<template>
<div>
  <form class="needs-validation">
              <div class="card border shadow-none mb-5">
                  <div class="card-body">
                      <div>
                          <div class="row">
                              <div class="col-lg-12 mb-3">
                                      <label class="form-label" for="gen-info-name-input">课程名称</label>
                                      <input type="text" class="form-control" id="gen-info-name-input" placeholder="课程名称" v-model="form.traningName"
                                      :class="{
                                          'is-invalid': submitted && $v.form.traningName.$error,
                                      }">
                                      <div
                                          v-if="submitted && $v.form.traningName.$error"
                                          class="invalid-feedback"
                                          >
                                              <span v-if="!$v.form.traningName.required">
                                                课程名称不能为空.
                                              </span>
                                      </div>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-lg-12 mb-3">
                                      <label class="form-label" for="gen-info-address-input">课程地址</label>
                                      <input type="text" class="form-control" id="gen-info-address-input" placeholder="课程地址" v-model="form.traningAddress"
                                      :class="{
                                          'is-invalid': submitted && $v.form.traningAddress.$error,
                                      }">
                                      <div
                                          v-if="submitted && $v.form.traningAddress.$error"
                                          class="invalid-feedback"
                                          >
                                              <span v-if="!$v.form.traningAddress.required">
                                                课程地址不能为空.
                                              </span>
                                      </div>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-lg-12 mb-3">
                                      <label class="form-label" for="gen-info-date-input">课程时间</label>
                                      <input type="text" class="form-control" id="gen-info-date-input" placeholder="课程时间" v-model="form.traningDate"
                                      :class="{
                                          'is-invalid': submitted && $v.form.traningDate.$error,
                                      }">
                                      <div
                                          v-if="submitted && $v.form.traningDate.$error"
                                          class="invalid-feedback"
                                          >
                                              <span v-if="!$v.form.traningDate.required">
                                                课程时间不能为空.
                                              </span>
                                      </div>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-lg-12 mb-3">
                                      <label class="form-label" for="gen-info-detail-input">课程详情</label>
                                      <input type="text" class="form-control" id="gen-info-detail-input" placeholder="课程详情" v-model="form.traningDetail">
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </form>
            <div class="text-end">
                <button type="button" class="btn btn-primary w-sm" @click="handleSaveTraning" v-if="this.action!='detail'">提交</button>
            </div> 
</div>
</template>
<style lang="scss">
.is-invalid .mx-input-wrapper .mx-input{
    border: 1px solid #ec4561 !important;
  }
  .is-invalid .mx-input-wrapper .mx-icon-calendar{
    color: #ec4561 !important;
  }
</style>
