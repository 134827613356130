<script>
import {queryTraningSurvey,downloadSurvey} from '@/apis/traning'
import {downFile} from '@/apis/common'
import simplebar from "simplebar-vue";
export default {
  components: { simplebar},
  data() {
      return {
          action:"new",
          traningCode:null,
          showType: "list",
          totalRows: 1,
          currentPage: 1,
          perPage: 10,
          pageOptions: [10, 25, 50, 100],
          tableData: [],
          items:[],
          showForm:false,
          statusShow:false,
          curSurvey:{},
          saveFileAction:"",
          pageRequest:{
              pageNum:1,
              pageSize:10
          }
      };
  },
  computed: {
    showDownload(){
        return this.totalRows>0
    }
  },
  watch:{
      "pageRequest.pageNum"() {
          this.loadData()
      },
      filter:{
          handler(){
              this.loadData();
          },
          deep:true
      }
  },
  created(){
        if(this.$route.query.traningCode!=undefined){
        this.traningCode = this.$route.query.traningCode;
        }
        if(this.$route.query.action!=undefined){
        this.action = this.$route.query.action;
        }
      this.loadData();
  },
  mounted() {
  },
  methods: {
      getParams(){
          let params = {...this.pageRequest,filter:this.filter}
          return params
      },
      loadData(){
          const params = this.getParams();
          queryTraningSurvey(params,this.traningCode).then((res)=>{
              this.tableData = res.data;
              this.totalRows = res.page.total;
          });
      },
      handleDetail(survey){
        let star = JSON.parse(survey.star)
        this.curSurvey = survey
        this.curSurvey.questions = star.customService
        this.$refs.myFormModal.show()
      },
      handleDownload(){
        downloadSurvey(this.traningCode).then((res)=>{
            const fileName = res.data
            const url=`/api-gateway/service/download?fileType=Survey&fileName=${fileName}`
            downFile(url,"");
        })
      }
  }
};

</script>

<template>
    <div>
        <b-modal id="modal-standard" title="问卷详情" title-class="font-18" ref="myFormModal">                    
            <p>{{$t("traningSurvey.question1")}}: {{ curSurvey.traningName}}</p>
            <p>{{$t("traningSurvey.question2")}}: {{ curSurvey.traningDate}}</p>
            <p v-for="(item,index) in curSurvey.questions" :key="index">{{$t(item.i18n)}}<span style="color: red;font-weight: 500;">{{ item.star}}</span></p>
            <p>{{$t("traningSurvey.question12")}}:</p>
            <p>{{ curSurvey.suggestion}}</p>
        </b-modal>
        <div class="table-responsive">
            <simplebar>
                <table class="table align-middle table-nowrap table-check">
                <thead>
                    <tr>
                        <th scope="col">编号</th>
                        <th scope="col">课程名称</th>
                        <th scope="col">课程时间</th>
                        <th scope="col">得分</th>
                        <th scope="col">反馈时间</th>
                        <th style="width: 80px; min-width: 80px;"><button type="button" class="btn btn-info btn-sm" @click="loadData">刷新</button> <button type="button" class="btn btn-primary btn-sm" @click="handleDownload" v-if="showDownload">下载</button></th>
                    </tr><!-- end tr -->
                </thead><!-- end thead -->
                <tbody>
                    <tr v-for="row in tableData" :key="row.id">
                        <td>{{row.surveyId}}</td>
                        <td> <a class="text-body fw-medium" href="javascript:" @click="handleDetail(row)">{{row.traningName}}</a></td>
                        <td>{{row.traningDate}}</td>
                        <td>{{row.score}}</td>
                        <td>{{row.surveyTime}}</td>
                        <td><a @click="handleDetail(row)" href="javascript:" >查看</a>                                          
                        </td>
                    </tr><!-- end tr -->
                </tbody><!-- end tbody -->
        </table><!-- end table -->     
            </simplebar>
        </div>
        <div class="row g-0 text-center text-sm-start" v-if="totalRows>0">
            <div class="col-sm-6">
                <div>
                    <p class="mb-sm-0">Showing {{(pageRequest.pageNum-1)*pageRequest.pageSize+1}} to {{pageRequest.pageNum*pageRequest.pageSize}} of {{totalRows}} entries</p>
                </div>
            </div>
            <!-- end col -->
            <div class="col-sm-6">
                <ul class="pagination pagination-rounded justify-content-center justify-content-sm-end mb-sm-0">
                    <b-pagination v-model="pageRequest.pageNum" :total-rows="totalRows" :per-page="pageRequest.pageSize"></b-pagination>
                </ul><!-- end ul -->
            </div><!-- end col -->
        </div><!-- end row -->                       
    </div>
</template>
<style lang="scss" scoped>
.finish{
    vertical-align: super;
    color: red;
}
.border-light {
  border-color: #f8f7fc !important;
}
.bg-light {
    background-color: rgba(248, 247, 252,1)!important;
}
.search-box{
  .form-control{
      border-radius: 30px;
      padding-left: 40px;
  }
  .search-icon{
      font-size: 16px;    
      position: absolute;
      left: 13px;
      top: 2px;
      color: #959396;
      line-height: 38px;
  }
}
</style>