import request from '@/request'
export const traningDetail = (traningCode) => {
    return request({
        url:`/api-gateway/service/traningDetail/${traningCode}`,
        method:'get'
    });
}
export const queryTraning = (data) => {
    return request({
        url:`/api-gateway/service/queryTraning/${data.pageSize}/${data.pageNum}`,
        method:'post',
        data,
    });
}
export const saveTraning = (data) => {
    return request({
        url:'/api-gateway/service/saveTraning',
        method:'post',
        data,
    });
}
export const deleteTraning = (traningCode) => {
    return request({
        url:`/api-gateway/service/deleteTraning/${traningCode}`,
        method:'post',
    });
}
export const updateTraningFile = (data) => {
    return request({
        url:'/api-gateway/service/updateTraningFile',
        method:'post',
        data,
    });
}
export const queryTraningSurvey = (data,traningCode) => {
    return request({
        url:`/api-gateway/service/queryTraningSurvey/${traningCode}/${data.pageSize}/${data.pageNum}`,
        method:'post',
        data,
    });
}
export const downloadSurvey = (traningCode) => {
    return request({
        url:`/api-gateway/service/downloadSurvey/${traningCode}`,
        method:'get',
    });
}
